import { Redirect, Route } from "../../components/routing";

import { useOrganisation } from "src/libraries/organisation/hooks";

const SuccessPlusIndexRestriction: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const organisation = useOrganisation();

  if (organisation === undefined) {
    return null;
  }

  if (organisation?.is_eligible_for_auto_retries) {
    return <>{children}</>;
  } else {
    return <Redirect route={Route.NotFound} />;
  }
};

export default SuccessPlusIndexRestriction;
