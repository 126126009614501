import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { MULTI_ACCOUNT_CONFIG_KEY } from "./constants";

interface MultiAccountSessionStorage {
  currentOrgId: OrganisationResource["id"];
  previousOrgId: OrganisationResource["id"];
  isBannerDismissed: boolean;
  bypassProposedLinkChecks: boolean;
}
export const useMultiAccountSessionStorage = () => {
  const storage: MultiAccountSessionStorage = JSON.parse(
    sessionStorage.getItem(MULTI_ACCOUNT_CONFIG_KEY) ?? "{}"
  );

  const setSessionStorage = (values: Partial<MultiAccountSessionStorage>) => {
    sessionStorage.setItem(
      MULTI_ACCOUNT_CONFIG_KEY,
      JSON.stringify({
        ...storage,
        ...values,
      })
    );
  };

  return {
    sessionStorage: storage,
    setSessionStorage,
  };
};
