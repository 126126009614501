import _ from "lodash";

import { CountryCodeSupported } from "./country";

import { isRole, Role } from "src/common/config";

export const isSandboxEnvironment = isRole(Role.Sandbox);

export const isCountryCodeSupported = (countryCode: string) =>
  _.toUpper(countryCode) in CountryCodeSupported;
