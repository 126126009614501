import { useMemo, useRef } from "react";
import { orderBy } from "lodash";
import {
  HomeSchemeEnum,
  SchemeScheme as SuccessPlusScheme,
  PaymentAutoretryConfigurationResource,
  UpgradesStatus,
} from "@gocardless/api/dashboard/types";
import { UpgradeType } from "@gocardless/api/staff/types";

import { orderSchemeConfigs, schemeOrderFromHomeScheme } from "../consts";

import { useHomeScheme } from "./scheme.hooks";

import { useOrganisation } from "src/libraries/organisation/hooks";
import { useRelevantCreditor } from "src/libraries/creditor/hooks";
import {
  usePaymentAutoretryConfigurations,
  useUpdatePaymentAutoretryConfigurations,
} from "src/queries/payment_autoretry_configurations";
import { useUserDashboardPreference } from "src/queries/user";
import { useSchemeIdentifiers } from "src/libraries/scheme/hooks/useSchemeIdentifiers";
import { useUpgradeCheck } from "src/libraries/upgrade/hooks/useUpgradeCheck";

export const useMustContactSalesForSuccessPlus = () => {
  const creditor = useRelevantCreditor();
  if (creditor === undefined) {
    return undefined;
  }

  return creditor === null || !creditor.approved_for_intelligent_retries;
};

export const useHasIntelligentRetriesEnabled = () => {
  const configs = usePaymentAutoretryConfigurations();
  if (configs === undefined) {
    return undefined;
  }

  return configs.some((config) => config.enabled);
};

export const useRequiresPackageUpgrade = () => {
  const organisation = useOrganisation();
  const { upgrade: hasSuccessPlusUpgrade, loading: upgradeLoading } =
    useUpgradeCheck(UpgradeType.SuccessPlus, UpgradesStatus.Active);

  if (organisation === undefined || upgradeLoading) {
    return undefined;
  }

  if (!organisation.is_on_pricing_v3) return false; // TODO: Remove in PSC-2909

  return !hasSuccessPlusUpgrade;
};

export const useHasIntelligentRetriesAdminDisabled = () => {
  const configs = usePaymentAutoretryConfigurations();
  if (configs === undefined) {
    return undefined;
  }

  return configs.every((schemeConfig) => schemeConfig.admin_disabled);
};

export const useHasDismissedIBPWaitlistBanner = () => {
  const dashboard_preferences = useUserDashboardPreference();
  if (dashboard_preferences === undefined) {
    return undefined;
  }
  return dashboard_preferences?.ibp_retry_waitlist_banner_dismissed;
};

export const useHasScheme = (scheme: HomeSchemeEnum) => {
  const schemeIdentifiers = useSchemeIdentifiers();
  if (schemeIdentifiers === undefined) {
    return undefined;
  }
  return !!schemeIdentifiers.filter(
    (schemeIdentifierResource) => schemeIdentifierResource.scheme === scheme
  ).length;
};

export const useCustomerNotificationEnabled = () => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }
  return organisation.customer_notifications_enabled;
};

export const useHasStandardAgreement = () => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return organisation && !organisation.should_pay_for_intelligent_retries;
};

export const useHasSelectedPackage = () => {
  const organisation = useOrganisation();

  return !!organisation?.organisation_preferences
    ?.merchant_has_requested_package;
};

export const useHasSuccessPlusUnbundled = () => {
  const organisation = useOrganisation();
  if (organisation === undefined) {
    return undefined;
  }

  return !!organisation.with_success_plus_fee_unbundled;
};

export const useSortedSchemesForIntelligentRetries = () => {
  const homeScheme = useHomeScheme();

  if (homeScheme) {
    return schemeOrderFromHomeScheme[homeScheme];
  }

  return orderBy(schemeOrderFromHomeScheme[SuccessPlusScheme.Bacs], "desc");
};

export const useUpdatePaymentAutoretryConfigurationByScheme = ({
  onEnable,
  onUpdate,
  onError,
}: {
  onEnable: (scheme: SuccessPlusScheme) => void;
  onUpdate: (scheme: SuccessPlusScheme) => void;
  onError: () => void;
}) => {
  const homeScheme = useHomeScheme();
  const rawSchemeConfigs = usePaymentAutoretryConfigurations();

  const schemeConfigs = useMemo(() => {
    if (rawSchemeConfigs === undefined || homeScheme === undefined) {
      return undefined;
    }
    return orderSchemeConfigs(homeScheme, rawSchemeConfigs);
  }, [homeScheme, rawSchemeConfigs]);

  const configToUpdateRef = useRef<{
    scheme: SuccessPlusScheme;
    isBeingEnabled: boolean;
  }>();

  const sendRequestToUpdateConfigs = useUpdatePaymentAutoretryConfigurations({
    onUpdateSuccess: () => {
      if (!configToUpdateRef.current) {
        throw new Error("configToUpdateRef was not initialized");
      }

      if (configToUpdateRef.current.isBeingEnabled) {
        onEnable(configToUpdateRef.current.scheme);
      } else {
        onUpdate(configToUpdateRef.current.scheme);
      }
    },
    onUpdateError: onError,
  });

  const sendUpdateConfigRequest = (
    configToUpdate: PaymentAutoretryConfigurationResource
  ) => {
    if (schemeConfigs === undefined) {
      return;
    }

    const currentConfig = schemeConfigs.find(
      (c) => c.scheme === configToUpdate.scheme
    );

    if (!currentConfig) {
      throw new Error(
        `${configToUpdate.scheme} scheme is missing from user's autoretry configurations`
      );
    }

    configToUpdateRef.current = {
      scheme: configToUpdate.scheme,
      isBeingEnabled: !currentConfig.enabled && configToUpdate.enabled,
    };

    const newConfigs = schemeConfigs.map((c) => {
      if (c.scheme !== configToUpdate.scheme) {
        return c;
      }

      return configToUpdate;
    });

    sendRequestToUpdateConfigs(newConfigs);
  };

  return {
    schemeConfigs,
    sendUpdateConfigRequest,
  };
};
