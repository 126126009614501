import { useState } from "react";
import Router, { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import { getErrorsFromErrorResponse, useClearSWR } from "@gocardless/api/utils";
import { OrganisationResource } from "@gocardless/api/dashboard/types";
import { useTemporaryAccessTokenCreateForOrganisation } from "@gocardless/api/dashboard/temporary-access-token";
import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  PlainLink,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";

import { useAccessToken } from "../../../common/authorisation";
import { TrackingEvent } from "../../../common/trackingEvents";

import { Route, getRouteURL } from "src/common/routing";
import { useTwoFA } from "src/components/TwoFAContextProvider";
import { useMultiAccountSessionStorage } from "src/libraries/organisation/multi-accounts/useMultiAccountSessionStorage";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useOrgSwitcherData } from "src/libraries/organisation/multi-accounts/useOrgSwitcherData";
import { useToastNotification } from "src/hooks/useToastNotification";
import { LinkBuilder } from "src/components/routing";

const TwoFactorAuthErrors = {
  MissingOtpCode: "two_factor_auth_missing_otp_code",
  Forbidden: "forbidden",
} as const;

interface SwitchOrganisationOptions {
  organisationId?: OrganisationResource["id"];
  onSuccess?: () => void;
  onError?: () => void;
}

const TWO_FACTOR_AUTH_SUPPORT_URL =
  "https://support.gocardless.com/hc/articles/4405561614226-2-factor-Authentication";

const ToastMessage = ({
  message,
  footer,
}: {
  message: React.ReactNode;
  footer?: React.ReactNode;
}) => (
  <Box>
    <Text layout="block" spaceBelow={1.5} preset={TypePreset.Body_02}>
      {message}
    </Text>
    {footer ? <Box>{footer}</Box> : null}
  </Box>
);

export const useSwitchOrganisation = (options?: SwitchOrganisationOptions) => {
  const clearCache = useClearSWR();
  const { organisationId, onSuccess, onError } = options ?? {};

  const { loggedInOrganisation } = useOrgSwitcherData();

  const [accessToken, setAccessToken] = useAccessToken();
  const { showTwoFADialog } = useTwoFA();

  const [isReauthenticationRequired, setIsReauthenticationRequired] =
    useState(false);

  const router = useRouter();

  const { setSessionStorage } = useMultiAccountSessionStorage();
  const { sendEvent } = useSegment();

  const { triggerErrorNotification } = useToastNotification();

  const [createAccessToken, { isMutating, error }] =
    useTemporaryAccessTokenCreateForOrganisation({
      onSuccess: (response) => {
        const currentOrgId = loggedInOrganisation?.id ?? "";

        onSuccess?.();

        setAccessToken(response);

        setSessionStorage({
          previousOrgId: currentOrgId,
        });

        sendEvent(TrackingEvent.MULTI_ACCOUNT_ACCOUNT_CHANGED, {
          from_organisation_id: currentOrgId,
          to_organisation_id: organisationId,
        });

        clearCache();

        router.push(getRouteURL({ route: Route.Home })).then(() => {
          Router.reload();
        });
      },
      onError: async (accessTokenError) => {
        onError?.();
        const errors = await getErrorsFromErrorResponse(accessTokenError);
        const failureReason = errors[0]?.reason;

        switch (failureReason) {
          case TwoFactorAuthErrors.MissingOtpCode: {
            setIsReauthenticationRequired(true);
            showTwoFADialog({
              variant: "multiAccount",
              onSubmit: async (_, otpCode) =>
                switchOrganisation(organisationId, otpCode),
            });
            break;
          }

          case TwoFactorAuthErrors.Forbidden: {
            triggerErrorNotification({
              title: <Trans>Unable to switch account</Trans>,
              message: (
                <ToastMessage
                  message={
                    <Trans>
                      Ensure that Two-factor authentication (2FA) is enabled. If
                      the issue persists, please contact support.
                    </Trans>
                  }
                  footer={
                    <PlainLink
                      effect={HoverEffect.TextDecoration}
                      href={TWO_FACTOR_AUTH_SUPPORT_URL}
                      preset={TypePreset.Body_02}
                      rel="noopener noreferrer"
                      target="_blank"
                      weight={FontWeight.SemiBold}
                    >
                      <Trans>How do I enable 2FA?</Trans>
                    </PlainLink>
                  }
                />
              ),
              duration: 10_000,
            });
            break;
          }

          default: {
            triggerErrorNotification({
              title: <Trans>Unable to switch account</Trans>,
              message: (
                <ToastMessage
                  message={
                    <Trans>
                      There was an error loading the chosen account. Please try
                      again.
                    </Trans>
                  }
                  footer={
                    <LinkBuilder route={Route.Accounts}>
                      {(result) => (
                        <PlainLink
                          {...result}
                          effect={HoverEffect.TextDecoration}
                          preset={TypePreset.Body_02}
                          weight={FontWeight.SemiBold}
                        >
                          <Box layout="flex" alignItems={AlignItems.Center}>
                            <Trans>Try again</Trans>

                            <Space layout="inline" h={0.5} />
                            <Icon name={Glyph.ArrowForward} size="12px" />
                          </Box>
                        </PlainLink>
                      )}
                    </LinkBuilder>
                  }
                />
              ),
            });
          }
        }

        sendEvent(TrackingEvent.MULTI_ACCOUNT_ACCOUNT_CHANGE_FAILED, {
          from_organisation_id: loggedInOrganisation?.id ?? "",
          to_organisation_id: organisationId,
          failure_reason: failureReason,
        });
      },
    });

  const switchOrganisation = async (
    orgId: OrganisationResource["id"] = "",
    otpCode?: string
  ) => {
    if (accessToken?.links?.organisation)
      await createAccessToken({
        links: { organisation: orgId },
        ...(otpCode ? { otp_code: otpCode } : {}),
      });
  };

  return {
    error,
    isReauthenticationRequired,
    submit: switchOrganisation,
    isSubmitting: isMutating,
  };
};
