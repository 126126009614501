import { FC, ReactNode } from "react";

import { Redirect, Route } from "src/components/routing";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/libraries/organisation/hooks";

interface OrganisationRestrictionProps {
  children: ReactNode;
  restrictForPaymentProviders?: boolean;
}

/**
 * Some organisation types may not be allowed to access certain pages in the dashboard.
 * This component provides a redirect to the NotFound page for those types.
 */
const OrganisationRestriction: FC<OrganisationRestrictionProps> = ({
  children,
  restrictForPaymentProviders = false,
}) => {
  const { organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;

  if (restrictForPaymentProviders && isPaymentProvider) {
    return <Redirect route={Route.NotFound} />;
  }

  return <>{children}</>;
};

export default OrganisationRestriction;
