import { SortMode } from "@gocardless/api/dashboard/types";
import { useUserListOrganisationsSelf } from "@gocardless/api/dashboard/user";

import { useOrganisation } from "src/libraries/organisation/hooks";

export const useOrgSwitcherData = () => {
  const loggedInOrganisation = useOrganisation() ?? {};

  const {
    data: { list_organisations: organisationsList } = {},
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  } = useUserListOrganisationsSelf({
    limit: 500,
    sort_mode: SortMode.AccessCount,
  });

  const parentOrganisation =
    organisationsList?.find((org) => org.depth === 0) ?? {};

  const currentOrganisation =
    organisationsList?.find((org) => org.id === loggedInOrganisation.id) ?? {};

  const sortedOrganisations =
    organisationsList
      ?.filter((org) => org.id !== currentOrganisation?.id)
      .slice(0, 3) ?? [];

  return {
    organisations: sortedOrganisations,
    loggedInOrganisation: currentOrganisation,
    parentOrganisation,
    isLoading: isLoadingListOrganisations,
    error: listOrganisationsError,
  };
};
